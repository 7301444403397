/* Collage section */
.collage {
    position: relative;
    overflow: hidden; /* Ensures no overflow from scrolling */
}

.collage-track {
    display: flex;
    width: calc(30vw * 5 + 3vw * 4); /* Adjust based on viewport width and number of images */
    animation: scroll 20s linear infinite;
}

.collage-track img {
    width: 30vw; /* Image width relative to viewport width */
    height: auto; /* Maintain aspect ratio */
    margin-right: 3vw; /* Space between images relative to viewport width */
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-30vw * 3 - 3vw * 3)); /* Adjust to move three images completely */
    }
}

/* Footer section */
.footer-container {
    background-image: url('../../../assets/productSection/wave.png');
    background-size: cover;
    background-position: top;
    position: relative;
    padding: 2vh 0; /* Adjust padding for responsiveness */
}

.vertical-text {
    font-weight: 900;
    font-size: 2.5vw; /* Font size relative to viewport width */
    color: #F8F7F0;
    padding-top: 1vh; /* Adjust for vertical alignment */
    text-align: center;
    transform: rotate(90deg);
    white-space: nowrap; /* Prevents text wrapping */
    display: inline-block; /* Ensure proper alignment */
}

.icon-color {
    color: #4CAC57;
  }
  