.bigCircle {
    width: 100%; /* Adjust this as needed */
    height: auto; /* Adjust this as needed */
    max-width: 748px; /* Max width for container */
    max-height: 748px; /* Max height for container */
    position: relative;
    overflow: visible; /* Ensures image doesn't overflow */
    border-radius: 50%; /* Make it a circle */
  }
  
  .bigCircle img {
    width: 100%; /* Make the image fill the container */
    height: 120%; /* Make the image fill the container */
    object-fit: cover; /* Ensure the entire image is visible */
    position: absolute;
    overflow: visible; /* Ensures image doesn't overflow */
    top: -65px;
    right:-185px
  }
  .arch-text {
    display: block;
    margin: 0 auto; /* Center the SVG horizontally */
    position: relative; /* Ensure the SVG is positioned correctly */
    z-index: 2; /* Bring the text to the front */
  }
  
  .containerSvg {
    display: flex;
    align-items: center; /* Vertically center the SVG */
    height: 300px; /* Adjust height as needed */
    overflow: visible; /* Ensure the SVG isn't clipped */
    position: relative; /* Relative positioning for layout control */
    z-index: 1; /* Set a lower z-index if necessary */
  }
  
