.bigCircleWhite {
    width: 100%; /* Adjust this as needed */
    height: auto; /* Adjust this as needed */
    max-width: 748px; /* Max width for container */
    max-height: 748px; /* Max height for container */
    position: relative;
    overflow: visible; /* Ensures image doesn't overflow */
    border-radius: 50%; /* Make it a circle */
  }
  
  .bigCircleWhite img:nth-of-type(2) {
    width: 100%; /* Make the image fill the container */
    height: 122%; /* Make the image fill the container */
    object-fit: cover; /* Ensure the entire image is visible */
    position: absolute;
    overflow: visible; /* Ensures image doesn't overflow */
    top: -11.8%;
    right: -32%;
  }
  .font-normal {
    font-weight: normal;
  }
  
  .font-black {
    font-weight: bold;
  }
  