.topSectionOurStory {
    background-image: url('../../assets/Our Story - Why ChiaDia/We believe in magic bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    height: 46.57vh;
    position: relative;
    overflow: hidden;
}

.os {
    width: 100%;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
    .topSectionOurStory {
        height: 28vh; /* Adjust height for mobile */
    }
}

path {
  fill: transparent;
}

text {
  fill: #4CAC57;
}