/* SupplierAsset.css */
.supplierAsset {
    background-image: url('../../assets/contact/SUPPLIERS BAR.svg'); /* Ensure the path is correct */
    background-size: contain; /* Adjust the size of the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    height: 100%; /* Adjust the height as needed */
    width: 100%;
    position: relative;
  }

.supplierAssetMobile {
  background-image: url('../../assets/contact/SUPPLIERS BAR.svg'); /* Ensure the path is correct */
  background-size:contain; /* Adjust the size of the background image */
  background-position: center;
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  height: 100%; /* Adjust the height as needed */
  width: 100%;
  position: relative;
}

.vertical-text {
  font-weight: 900;
  font-size: 2.5vw; /* Font size relative to viewport width */
  color: #F8F7F0;
  padding-top: 1vh; /* Adjust for vertical alignment */
  text-align: center;
  transform: rotate(90deg);
  white-space: nowrap; /* Prevents text wrapping */
  display: inline-block; /* Ensure proper alignment */
}

.vertical-textMobile {
  font-weight: 900;
  font-size: 2.5vw; /* Font size relative to viewport width */
  color: #F8F7F0;
  padding-top: 1vh; /* Adjust for vertical alignment */
  text-align: center;
  white-space: nowrap; /* Prevents text wrapping */
  display: inline-block; /* Ensure proper alignment */
}