.middleSectionWhy {
    background-image: url('../../../assets/Our Story - Why ChiaDia/The-power-of-our-super-ingredients-bg.png');
    background-repeat: no-repeat;
    background-position: center; /* Centers the image */
    background-size: cover; /* Makes the image cover the full screen */
    width: 100vw; /* Full width */
    min-height: vh; /* Full viewport height */
    display: flex; /* Ensures content can be centered */
    align-items: center; /* Vertically centers content */
    justify-content: center; /* Horizontally centers content (if needed) */
  }
  