@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "gopher", sans-serif;
}

/*MIGHT NEED TO CHANGE THIS*/
html, body {
    overflow-x: hidden;
  }
  