.dynamic-image {
    /* Adjust properties for responsiveness */
    max-width: 10vw; /* Adjust max width for responsiveness */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Optional: Add media queries if needed */
  @media (max-width: 768px) {
    .dynamic-image {
      max-width: 20vw; /* Larger size for smaller screens */
    }
  }
  