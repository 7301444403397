.pc {
  position: relative;
}

.transition-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.slider-up {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: transform 1.8s ease-in-out;
}

.closed .slider-up {
  transform: translateY(100%);
}

.open .slider-up {
  transform: translateY(-200%);
}
