.Info {
  overflow: hidden;
  max-height: 0; /* Start with max-height of 0 for the closed state */
  opacity: 0; /* Start with opacity of 0 for the closed state */
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out; /* Smooth transition */
}

.Info.open {
  max-height: 500px; /* Adjust as needed to accommodate the content */
  opacity: 1; /* Full opacity when open */
}

.smallCircle {
  width: 100%; /* Adjust this as needed */
  height: auto; /* Adjust this as needed */
  max-width: 257; /* Max width for container */
  max-height: 257; /* Max height for container */
  position: relative;
  overflow: visible; /* Ensures image doesn't overflow */
  border-radius: 50%; /* Make it a circle */
}

.smallCircle img {
  width: 100%; /* Make the image fill the container */
  height: 125%; /* Make the image fill the container */
  object-fit: cover; /* Ensure the entire image is visible */
  position: absolute;
  overflow: visible; /* Ensures image doesn't overflow */
  left: 33%;
  top: -12%;
}

.line {
  width: 15.78vw; /* Default width for larger screens */
  height: 1.7vh;   /* Default height for larger screens */
  background: #F8F7F0;
  opacity: 1;
  border: none;
  margin-top: 8%; 
  margin-left: 5%;
}

@media (max-width: 768px) { /* For mobile devices */
  .line {
    width: 35vw; /* Make the line longer on mobile */
    height: 1vh; /* Reduce height for mobile */
    margin-top: 5%; /* Adjust the margin for mobile */
  }
}


.inside {
  padding-left: 10px;
}

.outside {
  position: relative;
  left: -14px;
}

@keyframes maskMoveUp {
  0% {
    mask-position: center bottom; /* Start from bottom */
    -webkit-mask-position: center bottom; /* Safari support */
  }
  50% {
    mask-position: center middle; /* Move halfway */
    -webkit-mask-position: center middle; /* Safari support */
  }
  100% {
    mask-position: center top; /* End at top */
    -webkit-mask-position: center top; /* Safari support */
  }
}

.mask-animate {
  animation: maskMoveUp 1s ease-out forwards; /* Slowed down animation to 6 seconds */
}





/* Animation for dots filling up */
@keyframes fillDots {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
} 

/* Animation for the ingredient text appearing */
@keyframes appearText {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dot-wrapper {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.dots {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to right, #000 50%, transparent 50%);
  background-size: 10px 10px;
  background-repeat: repeat-x;
  animation: fillDots 0.1s ease-out;
  animation-fill-mode: forwards;
}

.text {
  opacity: 0;
  transform: translateY(10px);
  animation: appearText 0.1s ease-out;
  animation-fill-mode: forwards;
}

