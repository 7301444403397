.ps .img-container img {
    transition: transform 0.1s linear;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @keyframes moveLeftRight {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(calc(40vw - 3000px));
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes moveLeftRightMobile {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(calc(10vw - 2000px));
    }
    100% {
      transform: translateX(0);
    }
  }

  .animated-img {
    overflow: visible;
    animation: moveLeftRight 5s linear infinite;
  }
  
  .animated-img-mobile {
    overflow: visible;
    animation: moveLeftRightMobile 5s linear infinite;
    display: none; /* Hide mobile animation by default */
  }

  @media (max-width: 768px) { /* For mobile and tablet */
    .animated-img {
      display: none;
    }
  
    .animated-img-mobile {
      display: block;
    }
  }