.bg-uspBackground {
  background-image: url('../../../assets/USP/usps bar.svg'); /* Use your imported image */
  background-size: cover; /* Ensure the background covers the entire div */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating of the background */
}

/* usp.css */
.infiniteSlider {
  display: flex;
  justify-content: start;
  align-items: center;
  white-space: nowrap;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* For mobile devices, make the animation faster */
@media (max-width: 768px) {
  .infiniteSlider {
    animation: scroll 20s linear infinite; /* Adjust the speed as needed */
  }
}
