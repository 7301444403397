.heroContainer {
    overflow: visible; /* Ensure the container doesn't hide overflow */
}

.stuff img {
    opacity: 0;
    transform: scale(0);
    transition: transform 1s ease-out, opacity 1s ease-out;
}

.stuff.animate img {
    opacity: 1;
    transform: scale(1);
}

.stuff .start-center {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-500px, 0); /* Center element exactly */
}

.stuff .start-center2 {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(500px, 0); /* Center element exactly */
}
.stuff .start-center3 {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-100px, -100px); /* Center element exactly */
}

.stuff.animate .final-pos-fig7 {
    top: 12.5rem;
    left: 22.5rem;
    transform: translate(0, 0);
}

.stuff.animate .final-pos-fig8 {
    top: 17rem;
    right: 29.5rem;
    transform: translate(0, 0);
}

.stuff.animate .final-pos-fig9 {
    top: 31rem;
    right: 23rem;
    transform: translate(0, 0);
}

.stuff.animate .final-pos-fig10 {
    top: 34rem;
    left: 26rem;
    transform: translate(0, 0);
}
.stuff.animate .final-pos-fig11 {
    top: 13rem;
    left: 40rem;
    transform: translate(0, 0);
}
.stuff.animate .final-pos-fig2 {
    top: 40rem;
    right: 44rem;
    transform: translate(0, 0);
}
.stuff.animate .final-pos-fig3 {
    top: 36rem;
    right: 44rem;
    transform: translate(0, 0);
}
.stuff.animate .final-pos-fig4 {
    top: 18rem;
    right: 45.6rem;
    transform: translate(0, 0);
}